import { MenuEntry, menuStatus } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://albaswap.com/#/swap',
    calloutClass: 'menulink'
  },
  {
    label: 'About',
    icon: 'AboutIcon',
    href: '#',
    calloutClass: 'menulink'
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    calloutClass: 'menulink',
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://farms.albaswap.com/farms',
    calloutClass: 'menulink'
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: 'https://farms.albaswap.com/pools',
    calloutClass: 'menulink'
  },
  {
    label: 'IDO',
    icon: 'IfoIcon',
    href: '#',
    calloutClass: 'menulink'
  },
  // {
  //   label: 'Shop',
  //   icon: 'ShopIcon',
  //   href: '#',
  // },
  {
    label: 'NFT',
    icon: 'NftIcon',
    href: '#',
    calloutClass: 'menulink'
  },
  {
    label: 'Lottery',
    icon: 'TicketIcon',
    href: '#',
    calloutClass: 'menulink'
  },
  {
    label: 'Contact',
    icon: 'ContactIcon',
    href: '#',
    calloutClass: 'menulink'
  },
  {
    label: 'Privacy Policy',
    icon: 'privacyIcon',
    href: '#',
    calloutClass: 'menulink'
  },
  //  {
  //   label: 'Contact',
  //   icon: 'ContactIcon',
  //   href: '/',
  //   calloutClass: 'menulink'
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '#',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '#',
  // },
  // {
  //   label: 'Team Battle',
  //   icon: 'TeamBattleIcon',
  //   href: '/competition',
  //   status: menuStatus.SOON,
  // },
  // {
  //   label: 'Teams & Profile',
  //   icon: 'GroupsIcon',
  //   items: [
  //     {
  //       label: 'Leaderboard',
  //       href: '#',
  //     },
  //     {
  //       label: 'Task Center',
  //       href: '#',
  //     },
  //     {
  //       label: 'Your Profile',
  //       href: '#',
  //     },
  //   ],
  // },

  // {
  //   label: 'IFO',
  //   icon: 'IfoIcon',
  //   href: '#',
  // },
  // {
  //   label: 'More',
  //   icon: 'MoreIcon',
  //   calloutClass: 'menulink',
  //   items: [
  //     {
  //       label: 'Blog',
  //       href: '#',
  //     },
  //   ],
  // },
]

export default config
