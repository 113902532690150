import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle` 
  * {
      font-family: 'Poppins', sans-serif;
  }
  body {
    //background-color: ${({ theme }) => theme.colors.background};
    background: #0d0d0d;

    img {
      height: auto;
      max-width: 100%;
    }
  }
  *::-webkit-scrollbar {
    width: 5px;
  }
  *::-webkit-scrollbar-thumb {
    background: #fd9919;
    border-radius: 8px;
  }
  .white_box {
    box-shadow: -4px 5px 10px 2px rgb(0 0 0 / 20%);
  }
  button {
    outline: none !important;
  }
  a:hover {
    text-decoration: none !important;
    color: #fff !important;
  }
  .header_btn {
    padding: 10px 20px;
    font-size: 16px;
    box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset !important;
    height: 40px !important;
    // background-color: #fd9919 !important;
    // color: #000;
    letter-spacing: 0px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 600;
  }
  #swap-currency-input input::placeholder,
  #swap-currency-output input::placeholder,
  #add-liquidity-input-tokena input::placeholder,
  #add-liquidity-input-tokenb input::placeholder {
    color: #fd9919;
  }
  // #swap-currency-input div,
  // #swap-currency-output div,
  // #add-liquidity-input-tokena div,
  // #add-liquidity-input-tokenb div {
  //   color: #37373e;
  // }
  #swap-currency-input input {
    font-weight: 600 !important;
    color: #fd9919;
  }
  #swap-currency-output input {
    font-weight: 600 !important;
    color: #fd9919;
  }
  #add-liquidity-input-tokena input,
  #add-liquidity-input-tokenb input {
    font-weight: 600 !important;
    color: #fd9919;
  }
  .toggle_wrapper div {
    background-color: #3c3742;
    border-radius: 30px;
  }
  .toggle_wrapper div a {
    background-color: #3c3742;
  }
  .toggle_wrapper div a:hover {
    color: #fff !important;
  }
  .toggle_wrapper div a.active {
    background-color: #fd9919;
    color: #0b0b0b;
  }
  button.farms_unlock_btn,
  a.farms_unlock_btn { 
    border-radius: 5px;
    outline: none !important;
  }
  .menulink:hover {
    background-color: #fd9919;
  }
  button.wallet_btn {
    background-color: #292929;
    border-radius: 5px;
    opacity: 1 !important;
  }
  button.wallet_btn:hover {
    opacity: 0.7 !important;
  }
  // button.open-currency-select-button {
  //   background-color: #fd9919 !important;
  // }
  // button.open-currency-select-button div {
  //   color: #fff !important;
  // }
  .tooltip_bg div {
    background: transparent;
  }
  .sort_btn div:nth-child(2) {
    background-color: #292929;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }
  .seperator {
    background-color: rgb(253 153 25 / 30%);
  }
  #token-search-input::placeholder {
    color: #fff;
  }
  // .token_item_list div {
  //   color: #000;
  // }
  .token_item_list:hover {
    background-color: #fd9919;
  }
  .token_item_list:hover div {
    color: #fff;
  }
  .shadowBorder{
  	width: 436px;
  	max-width: 100%;
  }
  .shadowBorder > div{
  	box-shadow: #fd9919 0px 1px 10px;
  }

  .ct-particle-animate {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.ct-particle-animate div {
    position: absolute;
    top: 0;
    left: 0;
}
.ct-particle-animate #ct_particle_animate-a461fd71 {
    left: 7%;
    top: 18%;
}
.ct-particle-animate .shape-animate1 {
    animation: animationFramesTwo 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd72 {
    left: 25%;
    top: 10%;
}
.ct-particle-animate .shape-animate2 {
    animation: animationFramesOne 15s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd73 {
    left: 18%;
    top: 45%;
}
.ct-particle-animate #ct_particle_animate-a461fd83 {
    left: 18%;
    top: 15%;
}
.ct-particle-animate .shape-animate3 {
    animation: animationFramesOne 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd74 {
    left: 72%;
    top: 50%;
}
.ct-particle-animate #ct_particle_animate-a461fd84 {
    left: 70%;
    top: 25%;
}
.ct-particle-animate .shape-animate4 {
    animation: animationFramesFour 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd75 {
    left: 74%;
    top: 24%;
}
.ct-particle-animate .shape-animate5 {
    animation: animationFramesThree 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd77 {
    left: 20%;
    top: 70%;
}
.ct-particle-animate #ct_particle_animate-a461fd87 {
    left: 20%;
    top: 35%;
}
.ct-particle-animate .shape-left-right {
    animation: NewsletterAnimated2 12s infinite linear alternate;
}
.ct-particle-animate #ct_particle_animate-a461fd76 {
    left: 90%;
    top: 60%;
}
.ct-particle-animate #ct_particle_animate-a461fd86 {
    left: 90%;
    top: 29%;
}
.ct-particle-animate .shape-right-left {
    animation: NewsletterAnimated1 12s infinite linear alternate;
}
@keyframes animationFramesOne {
    0% {
        transform: translate(0, 0) rotate(0);
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        transform: translate(111px, 72px) rotate(72deg);
    }
    60% {
        transform: translate(93px, 122px) rotate(108deg);
    }
    80% {
        transform: translate(-70px, 72px) rotate(124deg);
    }
    100% {
        transform: translate(0, 0) rotate(0);
    }
}
@-webkit-keyframes animationFramesOne {
    0% {
        -webkit-transform: translate(0, 0) rotate(0);
    }
    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        -webkit-transform: translate(111px, 72px) rotate(72deg);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(-40px, 72px) rotate(124deg);
    }
    100% {
        -webkit-transform: translate(0, 0) rotate(0);
    }
}
@keyframes animationFramesTwo {
    0% {
        transform: translate(0, 0) rotate(0) scale(1);
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg) scale(1);
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }
    80% {
        transform: translate(-40px, 72px) rotate(104deg) scale(1.1);
    }
    100% {
        transform: translate(0, 0) rotate(0) scale(1);
    }
}
@-webkit-keyframes animationFramesTwo {
    0% {
        -webkit-transform: translate(0, 0) rotate(0) scale(1);
    }
    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    }
    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg) scale(1);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }
    80% {
        -webkit-transform: translate(-40px, 72px) rotate(104deg) scale(1.1);
    }
    100% {
        -webkit-transform: translate(0, 0) rotate(0) scale(1);
    }
}
@keyframes animationFramesThree {
    0% {
        transform: translate(165px, -179px);
    }
    100% {
        transform: translate(-346px, 200px);
    }
}
@-webkit-keyframes animationFramesThree {
    0% {
        -webkit-transform: translate(165px, -179px);
    }
    100% {
        -webkit-transform: translate(-346px, 200px);
    }
}
@keyframes animationFramesFour {
    0% {
        transform: translate(-300px, 151px) rotate(0);
    }
    100% {
        transform: translate(251px, -200px) rotate(180deg);
    }
}
@-webkit-keyframes animationFramesFour {
    0% {
        -webkit-transform: translate(-300px, 151px) rotate(0);
    }
    100% {
        -webkit-transform: translate(251px, -200px) rotate(180deg);
    }
}
@keyframes animationFramesFive {
    0% {
        transform: translate(61px, -99px) rotate(0);
    }
    21% {
        transform: translate(4px, -190px) rotate(38deg);
    }
    41% {
        transform: translate(-139px, -200px) rotate(74deg);
    }
    60% {
        transform: translate(-263px, -164px) rotate(108deg);
    }
    80% {
        transform: translate(-195px, -49px) rotate(144deg);
    }
    100% {
        transform: translate(-1px, 0) rotate(180deg);
    }
}
@-webkit-keyframes animationFramesFive {
    0% {
        -webkit-transform: translate(61px, -99px) rotate(0);
    }
    21% {
        -webkit-transform: translate(4px, -190px) rotate(38deg);
    }
    41% {
        -webkit-transform: translate(-139px, -200px) rotate(74deg);
    }
    60% {
        -webkit-transform: translate(-263px, -164px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(-195px, -49px) rotate(144deg);
    }
    100% {
        -webkit-transform: translate(-1px, 0) rotate(180deg);
    }
}
@keyframes animationFramesSix {
    0% {
        transform: translate(31px, -45px) rotate(0);
    }
    21% {
        transform: translate(4px, -80px) rotate(19deg);
    }
    41% {
        transform: translate(-70px, -100px) rotate(36deg);
    }
    60% {
        transform: translate(-133px, -84px) rotate(54deg);
    }
    80% {
        transform: translate(-98px, -24px) rotate(72deg);
    }
    100% {
        transform: translate(-1px, 0) rotate(180deg);
    }
}
@keyframes NewsletterAnimated1 {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-40px);
    }
    50% {
        transform: translateX(0);
    }
    75% {
        transform: translateX(40px);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes NewsletterAnimated2 {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(40px);
    }
    50% {
        transform: translateX(0);
    }
    75% {
        transform: translateX(-40px);
    }
    100% {
        transform: translateX(0);
    }
}

  @media screen and (max-width: 559px) {
    input[type="number"] {
      margin-top: 15px;
    }
  }
`

export default GlobalStyle
