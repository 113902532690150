import { useEffect } from 'react'
import useGetPriceData from './useGetPriceData'

const useGetDocumentTitlePrice = () => {
  // const priceData = useGetPriceData()

  const cakePriceUsd = 0

  useEffect(() => {
    document.title = `Alba`
  })
}
export default useGetDocumentTitlePrice
